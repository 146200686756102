@import 'variables';
@import 'webfonts';
//@import 'fonts';

//@import '../plugins/custom-scrollbar/css/jquery.mCustomScrollbar.scss';

//@import '../plugins/lightbox/dist/css/lightbox.scss';
//@import '../plugins/leaflet/leaflet.scss';
@import 'bootstrap/stylesheets/_bootstrap';
@import '../plugins/font-awesome/scss/font-awesome.scss';

html {width:100%; height: 100%; overflow-x:hidden;}
body {margin:0;}
#main {z-index: 2000;}
.anim { -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }


.print-picto {padding-right:45px;}

.nopadding { padding:0; }

.m-t-30 { margin-top : 30px;}
.m-r-30 { margin-right : 30px;}
.m-l-30 { margin-left : 30px;}
.m-b-0 { margin-bottom: 0;}
.m-b-15 { margin-bottom: 15px;}

.p-t-20 {padding-top:20px;}

.texte-bleu {color : #0082CE;}
.inline-block {display: inline-block;}

.font-normal { font-weight: normal;}
.padding-15 {padding: 15px;}
#bs-example-navbar-collapse-1 {z-index: 99999!important;}
.relative { position:relative; }
.link-absolute { position:absolute; top:0; right:0; bottom:0; left:0; }
#header {
    background:transparent url('../img/background_home.jpg') no-repeat center;
    background-size:cover;
    &:not(.feuille-jaune) {height:150px;}
    position:relative;
    z-index:1;
    &.header-home {background-position:center bottom;}
    .navbar {
        background-color:transparent;
        border:none;
        margin-bottom:0;
        .navbar-header {
            z-index : 99999;
            .navbar-toggle {
                border:none;
                background-color:transparent;
                padding-top: 30px;
                margin-bottom: 30px;

                .icon-bar {
                    background-color:white;
                }
            }

            .navbar-brand {
                height: auto;
            }

            .header-title {
                font-family: 'Gochi Hand';
                color:white;
                font-size:14px;
                text-align: center;
                float:left;
                width:calc(100% - 200px);
                margin-top:30px;
                line-height:110%;
            }
        }

        .navbar-collapse {
            background-color:white;
        }

        .header-clubs {
            list-style-type: none;

            > li { display: inline-block;}
        }
    }
}

#header.not-home {
    
    &:not(.feuille-jaune) {height:100px;} 
    background-position:top center;

    .navbar {
        .navbar-header {
            width:auto !important;
        }
    }

    .header-clubs {
        margin-left:50px;
        > li {
            a { padding:0 5px; margin-top:20px !important;}
            img {
                height:60px;
            }
        }
    }
}

#header.feuille-jaune {
    background: #F9E386;

    .navbar-brand {
        padding-top:10px;
        height: auto;
    }

    .header-title {
        font-family: 'Gochi Hand';
        color:#0082CE;
        font-size:14px;
        text-align: center;
        float:left;
        width:calc(100% - 200px);
        margin-top:20px;
        line-height:100%;
    }
    .printer {color : #444;margin-top:20px; font-size: 30px
    }
}



#accordion.feuille-jaune {
    h2 {background: url('../img/fond-header.svg');
        background-size: 100% 100%;
        font-size : 20px;

        a {color:#FFF;}

    }
    h4 {color : #0082CE;}
    .card-block { background: #F9E386!important;}
}

/* Slider */
#carousel-home {
    background-color:#f5f9f9;
    padding-top:30px;
    padding-bottom:30px;

    .date {
        color:#086CB3;
        font-size:12px;
        margin-top:5px;
    }

    .text {
        margin-top:5px;
        font-size:12px;
    }

    .carousel-control {
        width: 8.33%;

        &.left, &.right {
            background:none;
        }

        .fa {
            font-size: 60px;
            height: 30px;
            margin-top: -10px;
            width: 30px;
            display: inline-block;
            margin-top: -10px;
            position: absolute;
            top: 44%;
            z-index: 5;
            right:15px;
            color:#006db7;

            &.fa-angle-right {
                left:15px;
            }
        }
    }

    .carousel-inner {
        .item {
            .container {
                position:relative;
            }
        }
    }

    .carousel-indicators {
        bottom: -5px;
        li {
            border-color:#006db7;

            &.active {
                background-color:#006db7;
            }
        }
    }
}

#carousel-article {
    background:transparent url('../img/cadre_photo_bleu.svg') no-repeat top left;
    background-size:cover;
    padding:20px 20px 10px 20px;

    .carousel-inner img {
        width:100%;
    }
}

/* Club */

.clubs-home-item { padding:0; }

.clubs-home-items {
    img { width:100%; }
    .clubs-home-item-1 { width:49% }
    .clubs-home-item-2 { width:51% }
    .clubs-home-item-3 { width:52.5% }
    .clubs-home-item-4 { width:47.5%; margin-top:-3%; }
    .clubs-home-item-0-clone { margin-top:15px; }
}



.club-list {
    .club-item {
        img {
            width:100%;
        }
        margin-bottom:30px;
    }
}

#articles {
    margin-top:15px;
}

#content {
    padding:15px 0;

    h1 {
        color:#3290D6;
        font-size:26px;
    }

    .nav {
        li {
            margin-bottom:5px;
            a {
                color:white;
                background-color:#3290D6;
            }

            a:hover { opacity:0.8; }
        }
    }

    &.club-vert {
        h1 {
            color:#CED429;
        }

        .nav {
            li {
                margin-bottom:5px;
                a {
                    color:white;
                    background-color:#CED429;
                }
            }
        }

        #carousel-article {
            background-image: url('../img/cadre_photo_vert.svg');
        }
    }
    &.club-cyan {
        h1 {
            color:#86E3EE;
        }

        .nav {
            li {
                margin-bottom:5px;
                a {
                    color:white;
                    background-color:#86E3EE;
                }
            }
        }

        #carousel-article {
            background-image: url('../img/cadre_photo_cyan.svg');
        }
    }
    &.club-orange {
        h1 {
            color:#FE9F0E;
        }

        .nav {
            li {
                margin-bottom:5px;
                a {
                    color:white;
                    background-color:#FE9F0E;
                }
            }
        }

        #carousel-article {
            background-image: url('../img/cadre_photo_orange.svg');
        }
    }

    &.club-rose {
        h1 {
            color:#FE519B;
        }

        .nav {
            li {
                margin-bottom:5px;
                a {
                    color:white;
                    background-color:#FE519B;
                }
            }
        }

        #carousel-article {
            background-image: url('../img/cadre_photo_rose.svg');
        }
    }
}



/*
#slide-home {
  width: 100%;
  color: #000;

    .rsContent {


    }
}

@media screen and (min-width: 960px) and (min-height: 660px) {
    .royalSlider.heroSlider {
        height: 520px !important;
    }
}

@media screen and (min-width: 960px) and (min-height: 1000px) {
    .royalSlider.heroSlider {
        height: 360px !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .royalSlider.heroSlider {
        height: 150px !important;
        padding:15px 0;
    }
}
*/
@media (min-width: 768px) {
    #header {
        background-size:cover;
        z-index:1;
        .navbar {
            .navbar-header {
                .header-title {
                    font-size:30px;
                    text-shadow:2px 2px 2px #999;
                }
            }

            .navbar-collapse {
                /*background-color:transparent;*/
            }
        }
    }

    #header.not-home {
        .navbar {
            .navbar-collapse {
                background-color:white !important;
            }
        }
    }

    .clubs-home-items {
        margin-top:30px;
    }

    #header.feuille-jaune {
        .header-title {
            font-size:50px;
        }
    }

    #accordion.feuille-jaune {
        h2 {
            font-size : 35px;
        }
    }
}

@media only screen and (min-width: 996px) {


    .clubs-home-items {
        margin-top:30px;
        img { width:100%; }
        .clubs-home-item { padding:0 10px; }
        .clubs-home-item-1 { width:25%; }
        .clubs-home-item-2 { width:25%; }
        .clubs-home-item-3 { width:25%; }
        .clubs-home-item-4 { width:25%; margin-top:0;}
    }

}


@media only screen and (min-width: 1170px) {
    #header {
        background-size:cover;
        &:not(.feuille-jaune) {height:220px;}
        z-index: 0;
        .navbar {
            height:220px;
            .navbar-header {
                width:65%;
                .header-title {
                    font-size:40px;
                    text-align:center;
                }
            }

            .navbar-nav > li > a {
                color:white;
                font-weight:bold;
                text-transform: uppercase;
                text-shadow:2px 2px 2px #999;
            }

            .navbar-collapse {
                background-color:transparent;
            }
        }

        .navbar-default .navbar-nav > li > a {
            margin-top:30px;
        }
    }

    #header.not-home {
        .navbar {
            height:auto;
            .navbar-collapse {
                background-color:transparent !important;
            }
        }
    }

    /* Slider */
    #carousel-home {
        padding-top:60px;
        padding-bottom:60px;

        .carousel-indicators { display:none;}
    }

    #block-home-1 {
        position:relative;
        z-index:1000;
        margin-top: -30px;
        & > div {
            float:left;
        }

        .block-home-item-1 {
            width:230px;
        }

        .block-home-item-2 {
            width:660px;
            padding:0 30px;
        }

        .block-home-item-3 {
            width:230px;
        }
    }

    .clubs-home-items {
        margin-top:30px;
        img { width:100%; }

        .clubs-home-item { padding:0 10px; }

        .clubs-home-item-0 {
            width:20%;
            float:left;
            padding-left:0;

            img {
                width:80%;
                box-shadow: 5px 5px 5px #ccc;
            }
        }
        .clubs-home-item-1 { width:20%; }
        .clubs-home-item-2 { width:20%; }
        .clubs-home-item-3 { width:20%; }
        .clubs-home-item-4 { width:20%; margin-top:0; }
    }
}

@media (max-width: 1200px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}

#__bs_notify__ {
    height:5px !important;
    width:5px !important;
}


/** Preloader
 **************************************************************** **/
#preloader {
    position: fixed;
    z-index: 9999999;
    top: 0; bottom: 0;
    right: 0; left: 0;
    background: #fff;
}

.inner {
    position: absolute;
    top: 0; bottom: 0;
    right: 0; left: 0;

    width: 54px;
    height: 54px;
    margin: auto;
}

.page-loader{
 	display:block;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: #fefefe;
	z-index: 100000;
}

#preloader span.loader {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  font-size: 10px;
  text-indent: -12345px;
  border-top: 1px solid rgba(0,0,0, 0.08);
  border-right: 1px solid rgba(0,0,0, 0.08);
  border-bottom: 1px solid rgba(0,0,0, 0.08);
  border-left: 1px solid rgba(0,0,0, 0.5);

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

   -webkit-animation: spinner 700ms infinite linear;
   -moz-animation: spinner 700ms infinite linear;
   -ms-animation: spinner 700ms infinite linear;
   -o-animation: spinner 700ms infinite linear;
   animation: spinner 700ms infinite linear;

  z-index: 100001;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
