@font-face {
	font-family: 'Gochi Hand';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Gochi_Hand-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Open_Sans-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/Open_Sans-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

