$icon-font-path: '../fonts/';

$font-family-sans-serif:      'Open Sans', sans-serif;
$headings-font-family:      $font-family-sans-serif !default;
$headings-font-weight:      700 !default;

$nav-link-hover-bg:         #4F8785;

$primary_color:             #006561;
$secondary_color:           #4F8785;
$tertiary_color:            #00514C;
$quatro_color:            #00423e;

$color_vert: #cdd500;
$color_vert: #31e4ef;
$color_orange: #ffa100;
$color_rose: #ff509f;
$color_jeune: #ffe481;

$border-radius-base:        0px !default;
$border-radius-large:       0px !default;
$border-radius-small:       0px !default;